import { Pipe, PipeTransform } from '@angular/core';
import { Voucher } from '../../core/models/voucher.model';
import { VoucherStatus } from '../../core/enums/voucher-status.enum';

@Pipe({
  name: 'paymentDue'
})
export class PaymentDuePipe implements PipeTransform {

  transform(value: Voucher): string {
    if (value.status === VoucherStatus.Canceled || value.status === VoucherStatus.Rejected || value.status === VoucherStatus.Waiting) {
      return '-';
    } else if (!value.installments || value.installments.length === 0) {
      return '-';
    } else {
      const currentInstallment = value.installments
        .sort((a, b) => a.index > b.index ? -1 : a.index < b.index ? 1 : 0)
        .find(elm => !elm.isPaid);
      if (currentInstallment && new Date(currentInstallment.dueDate).getTime() < new Date().getTime()) {
        return 'Oui';
      } else {
        return 'Non';
      }
    }
  }

}
