import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LoginResolver } from './core/authentication/login.resolver';
import { AuthGuard } from './core/authentication/auth.guard';
import { MainComponent } from './pages/main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'partners',
        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'vouchers',
        loadChildren: () => import('./pages/vouchers/vouchers.module').then(m => m.VouchersModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: [LoginResolver]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
