import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Admin } from '../models/admin.model';

export const credentialsKey = 'karni_jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this._remember = !!localStorage.getItem(credentialsKey);
      const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
      if (savedCredentials) {
        this._credentials = JSON.parse(savedCredentials);
      }
    }
  }

  get credentials(): Admin | null {
    return this._credentials;
  }

  // tslint:disable-next-line:variable-name
  private _credentials: Admin | null;
  // tslint:disable-next-line:variable-name
  private _remember: boolean;

  logout(): Observable<boolean> {
    this.setCredentials();
    return of(true);
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  public setCredentials(credentials?: any, remember?: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      this._credentials = credentials || null;

      if (credentials) {
        let storage;
        if (this._remember) {
          storage = localStorage;
        } else {
          storage = remember ? localStorage : sessionStorage;
          this._remember = !!remember;
        }
        storage.setItem(credentialsKey, JSON.stringify(credentials));
      } else {
        sessionStorage.removeItem(credentialsKey);
        localStorage.removeItem(credentialsKey);
      }
    }
  }
}



