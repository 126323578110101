<div class="login-container">
  <div class="container-fluid h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-auto">
        <div class="card login-card">
          <div class="card-body">
            <p class="card-text text-center text-large">Bienvenue sur Karni backoffice.</p>
            <p class="card-text text-center text-large">Entrez votre login et mot de passe</p>
            <p class="text-center text-normal text-danger">
              <span *ngIf="!error">&nbsp;</span>
              <span *ngIf="error">{{ error }}</span>
            </p>
            <div class="">
              <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
                <div class="form-group">
                  <input formControlName="email"
                         type="text"
                         class="form-control"
                         id="email"
                         autocomplete="username"
                         placeholder="Adresse mail">
                </div>
                <div class="form-group">
                  <input formControlName="password"
                         type="password"
                         class="form-control"
                         id="password"
                         autocomplete="current-password"
                         placeholder="Mot de passe" required/>
                </div>
                <div class="form-row pt-2">
                  <div class="col">
                    <button [disabled]="loginForm.invalid || isLoading" (click)="login()" class="btn btn-primary btn-block btn-lg" type="submit">
                      <span translate>Connexion</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
