<nav class="container-fluid navbar-theme">
  <div class="row align-items-center">
    <div class="col-auto logo-col">
      <div class="logo"></div>
    </div>
    <div class="col"></div>
    <div *ngIf="credentials" class="col-auto">
      <button [matMenuTriggerFor]="menu" class="flat-btn">
        <span class="navbar-user__text">{{ credentials?.userName }}</span>
        <svg-icon svgClass="navbar-chevron" src="assets/icons/down-arrow.svg"></svg-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="logout()" mat-menu-item>
          <svg-icon class="mr-2" src="assets/icons/logout.svg"></svg-icon>
          <span class="text-normal">Se déconnecter</span>
        </button>
      </mat-menu>
    </div>
  </div>
</nav>
