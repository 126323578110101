import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { handleError } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) {
  }

  me(): Promise<any> {
    return this.http.get(`${environment.api.account}/user/api/general/me`)
      .toPromise().catch(handleError);
  }

  login(email: string, password: string): Promise<any> {
    const requestBody = {
      email,
      password
    };
    return this.http.post(`${environment.api.account}/user/api/access/admin/login`, JSON.stringify(requestBody))
      .toPromise().catch(handleError);
  }

  // Manager accounts

  createManagerAccount(account: any): Promise<any> {
    return this.http.post(`${environment.api.account}/user/api/manager`, JSON.stringify(account))
      .toPromise().catch(handleError);
  }

  deleteManagerAccount(id: string): Promise<any> {
    return this.http.delete(`${environment.api.account}/user/api/manager?id=${id}`)
      .toPromise().catch(handleError);
  }

}
