import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'missingData'
})
export class MissingDataPipe implements PipeTransform {

  transform(value: any, placeholder?: string | number): any {
    return value ?? placeholder ?? '-';
  }

}
