import { Pipe, PipeTransform } from '@angular/core';
import { VoucherStatus } from '../../core/enums/voucher-status.enum';

@Pipe({
  name: 'voucherStatus'
})
export class VoucherStatusPipe implements PipeTransform {

  transform(value: VoucherStatus): string {
    switch (value) {
      case VoucherStatus.Waiting:
        return 'En attente de validation';
      case VoucherStatus.Attributed:
        return 'En attente de paiement';
      case VoucherStatus.Available:
        return 'Disponible';
      case VoucherStatus.Activated:
        return 'Activé';
      case VoucherStatus.Paid:
        return 'Payé';
      case VoucherStatus.Rejected:
        return 'Rejeté';
      case VoucherStatus.Canceled:
        return 'Annulé';
      default:
        return '-';
    }
  }

}
