<mat-sidenav-container class="view-container">
  <mat-sidenav class="side-bar" mode="side" opened="true">
    <div class="sidenav-content">
      <div class="section" *ngFor="let item of list, let i = index">
        <a routerLink="{{item.link}}" routerLinkActive="active"
           class="nav-link">
          <svg-icon svgClass="dashboard-icon" [src]="item?.icon"></svg-icon>
          <span>{{item.title}}</span>
        </a>
      </div>
    </div>
    <div class="sidenav-footer">
      <div class="section" >
        <a routerLink="settings" routerLinkActive="active"
           class="nav-link">
          <svg-icon svgClass="dashboard-icon" src="assets/icons/settings.svg"></svg-icon>
          <span>Paramètres</span>
        </a>
      </div>
    </div>
  </mat-sidenav>


  <mat-sidenav-content>
    <div class="container-main">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
