import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  title: string;
  content: string;
  action: string;

  constructor(private dialogRef: MatDialogRef<ConfirmModalComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    if (this.data && this.data.title && this.data.content && this.data.action) {
      this.title = this.data.title;
      this.content = this.data.content;
      this.action = this.data.action;
    }
  }

  apply(): void {
    this.dialogRef.close(true);
  }

}
