import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../core/material/material.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavbarComponent } from './navbar/navbar.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissingDataPipe } from './pipes/missing-data.pipe';
import { ToDinarsPipe } from './pipes/to-dinars.pipe';
import { VoucherStatusPipe } from './pipes/voucher-status.pipe';
import { PaymentDuePipe } from './pipes/payment-due.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    ConfirmModalComponent,
    MissingDataPipe,
    ToDinarsPipe,
    VoucherStatusPipe,
    PaymentDuePipe
  ],
  exports: [
    NavbarComponent,
    MaterialModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    MissingDataPipe,
    ToDinarsPipe,
    VoucherStatusPipe,
    PaymentDuePipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
  ],
})
export class SharedModule { }
