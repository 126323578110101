import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../core/authentication/auth.service';
import { AccountService } from '../../core/services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TitleService } from '../../core/services/title.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });

  error: string;
  isLoading: boolean;

  constructor(private fb: FormBuilder,
              private authenticationService: AuthService,
              private router: Router,
              private dialog: MatDialog,
              private api: AccountService,
              private titleService: TitleService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Connexion');
    this.isLoading = false;
  }

  login(): void {
    this.isLoading = true;
    this.loginForm.disable();
    this.api.login(this.loginForm.get('email').value, this.loginForm.get('password').value)
      .then(user => {
        this.authenticationService.setCredentials(user, true);
        this.error = null;
        this.router.navigate(['/']).then(() => {
          console.log('Login successful: Redirecting...');
          console.clear();
        });
      }).catch(() => {
      this.error = 'Votre email ou votre mot de passe sont invalides.';
      this.loginForm.enable();
      this.loginForm.get('password').reset();
      this.isLoading = false;
    });
  }

}
