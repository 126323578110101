export function handleError(error: Response | any): Promise<any> {
  let errMsg: string;
  if (error instanceof Response) {
    const body = error.json() || '';
    const err = JSON.stringify(body);
    errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  } else {
    errMsg = error.message ? error.message : error.toString();
  }
  console.error(errMsg);
  return Promise.reject(errMsg);
}
