import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  list = [
    {
      title: 'Tableau de bord',
      link: '/dashboard',
      icon: 'assets/icons/dashboard.svg'
    },
    {
      title: 'Gestion des partenaires',
      link: '/partners',
      icon: 'assets/icons/partners.svg'
    },
    {
      title: 'Gestion des clients',
      link: '/clients',
      icon: 'assets/icons/users.svg'
    },
    {
      title: 'Gestion des coupons',
      link: '/vouchers',
      icon: 'assets/icons/vouchers.svg'
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }


}
