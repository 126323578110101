import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDinars'
})
export class ToDinarsPipe implements PipeTransform {

  transform(value: number, decimals: number = 3): string {
    return (value / 1000).toFixed(decimals);
  }

}
