<div class="content">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-dialog-content>
    <p class="text-normal">{{ content }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-end mt-1">
    <button class="btn btn-cancel" mat-dialog-close>
      Annuler
    </button>
    <button (click)="apply()" class="btn btn-primary ml-2">
      {{ action }}
    </button>
  </mat-dialog-actions>
</div>
