import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginResolver implements Resolve<any> {

  constructor(private authenticationService: AuthService,
              private router: Router) { }

  resolve(): void {
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

}
