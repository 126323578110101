export enum VoucherStatus {
  None,
  Waiting,
  Rejected,
  Attributed,
  Available,
  Activated,
  Canceled,
  Paid
}
