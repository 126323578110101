import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private api: AccountService,
              private authService: AuthService) { }


  canActivate(): Promise<boolean> {
    return this.api.me().then(value => {
      this.authService.setCredentials(value);
      return true;
    }).catch(() => {
      this.authService.logout();
      this.router.navigate(['/login']);
      return false;
    });
  }

}
